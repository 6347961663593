import { graphql } from "gatsby";
import React from "react";
import BottomCTA from "../components/BottomCta";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import {
  useSegment,
  trackView,
  appendTrackingParams
} from "../utils/analytics";
import { CTA_ID } from "../utils/analytics/constants";
import { nav } from "../utils/navigation";
import { Name, Type, Location, Category } from "../utils/analytics/constants";
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import HeroSection from "../components/BenchmarkerPage/HeroSection";
import YourBusinness from "../components/BenchmarkerPage/YourBusinness";
import PowerfulTool from "../components/BenchmarkerPage/PowerfulTool";
import SafeAndSecure from "../components/BenchmarkerPage/SafeAndSecure";
import Icon, { IconType } from "../components/Icon";
import AnalyzedNumbers from "../components/BenchmarkerPage/AnalyzedNumbers";
import Performance from "../components/BenchmarkerPage/Performance";
import Badges from "../components/BenchmarkerPage/Badges";
import DemoReport from "../components/BenchmarkerPage/DemoReport";

interface BenchmarkerProps {
  data: any;
}

const Benchmarker: React.FC<BenchmarkerProps> = ({ data }) => {
  const { segment, helpers } = useSegment();
  const { language } = useI18next();
  const { t } = useTranslation();

  const pageData = data;
  const locale = data?.locales?.edges[0]?.node.language;

  const hero = {
    gradientTitle: [t("Market-leading"), t("Amazon PPC evaluation")],
    cta: {
      text: t("Analyze your products now"),
      route: t("https://benchmarker.perpetua.io/")
    },
    marqueeData: [
      { text: t("100% free"), icon: IconType.locker },
      { text: t("100% secure"), icon: IconType.locker },
      { text: t("100% free"), icon: IconType.locker },
      { text: t("100% secure"), icon: IconType.locker }
    ],
    addSales: t("Amazon Ad Sales Analyzed")
  };

  const testimonials = [
    {
      image: pageData?.danRogers?.childImageSharp?.gatsbyImageData,
      description: t(
        "“I think it’s one of the best tools/reports to come to the Amazon space in the last few years, because of the actionable data that it provides.”"
      ),

      name: "Dan Rodgers"
    },
    {
      image: pageData?.benDonovan?.childImageSharp?.gatsbyImageData,
      description: t(
        "“It’s going to give you a lot of usable insights that you can take action on and begin improving your products, your overall sales, but of course also your PPC campaigns.”"
      ),
      name: "Ben Donovan"
    },
    {
      image: pageData?.vovaEven?.childImageSharp?.gatsbyImageData,
      description: t(
        "“The Benchmarker can actually help you understand how your PPC is standing against your competition, so you can improve it and get more sales.”"
      ),
      name: "Vova Even"
    }
  ];

  const toolFeatures = [
    {
      name: t("Summary"),
      description: t(
        "View your monthly advertising results for key performance indicators, such as ACOS, CPC, CTR and CVR."
      ),
      image: pageData?.summary?.childImageSharp?.gatsbyImageData
    },
    {
      name: t("Performance Matrix"),
      description: t(
        "Quickly see the performance of your top 10 products in the last month"
      ),
      image: pageData?.matrix?.childImageSharp?.gatsbyImageData
    },
    {
      name: t("Impact Drivers"),
      description: t(
        "Impact Drivers gives you an intelligent snapshot of the products with the highest impact on critical KPIs like ad Sales, ACOS, CPC, and more."
      ),
      image: pageData?.impact?.childImageSharp?.gatsbyImageData
    },
    {
      name: t("Product Analysis"),
      description: t(
        "Easily identify specific products in need of ad optimization based on 20k category benchmarks."
      ),
      image: pageData?.analysis?.childImageSharp?.gatsbyImageData
    },
    {
      name: t("Account Structure"),
      description: t(
        "Understand if your account structure has a strong foundation for success in comparison to high performers."
      ),
      image: pageData?.structure?.childImageSharp?.gatsbyImageData
    },
    {
      name: t("Ad Formats"),
      description: t(
        "Compare your performance for Sponsored Products and Sponsored Brands to that of your competitors."
      ),
      image: pageData?.ad?.childImageSharp?.gatsbyImageData
    }
  ];

  const safeAndSecureData = [
    {
      title: t("Ensuring your data privacy"),
      description: t(
        "The Benchmarker will use your data to analyze your account and to calculate your growth potential. We will never share your Amazon PPC data with a 3rd party for any reason."
      ),
      icon: <Icon className="" iconType={IconType.locker} />
    },
    {
      title: t("Ensuring your data security"),
      description: t(
        "We are aware of the responsibility that comes along with handling your data and take considerable efforts to ensure the highest security standards possible."
      ),
      icon: <Icon className="" iconType={IconType.defense} />
    }
  ];

  const performanceData = [
    {
      text: t("Sign Up free today"),
      className:
        "left-0 -top-5 max-w-[130px] sm:max-w-full -ml-4 sm:ml-10 lg:ml-14"
    },
    {
      text: t("Connect your Amazon Advertising account"),
      className: "right-0 -top-8 max-w-[130px] sm:max-w-[290px] -mr-4 sm:mr-0"
    },
    {
      text: t("Receive access to your analysis"),
      className:
        "left-0 -top-8 max-w-[130px] sm:max-w-[220px] -ml-4 sm:ml-18 lg:ml-20"
    }
  ];

  const analysedNumbersData = {
    data: [
      {
        text: t("PPC Benchmarks"),
        number: "20K+",
        className: "-tracking-widest from-orange-4 to-red-2"
      },
      {
        text: t("ASINs analyzed"),
        number: "170M+",
        className: "-tracking-widest from-red-1 to-purple-1"
      },
      {
        text: t("Monthly users"),
        number: "7K+",
        className: "tracking-tighter from-indigo-1 to-blue-1"
      }
    ],
    faqs: [
      //TODO taulant to find a way to render links inside the text. For now we are rewriting the text to not have any links.
      {
        title: t("What is the Benchmarker?"),
        content: t(
          "The Benchmarker will give you a clear picture of your Amazon PPC performance and provide actionable insights on how to improve the big four PPC metrics. Curious? Sign up today to access your own report. /n Since the dawn of ads, marketers have asked themselves: Is my ACOS/CPC/click-through rate/conversion rate “good”? /n A simple question with a complex answer. So-called “industry averages” will only get you so far because what you really want to know is: how does my ACOS/CPC/click-through rate/conversion rate compare to my competitors’: /n • on Amazon /n • in my sub-category and marketplace /n • according to the PPC ad format – whether it’s Sponsored Products or Sponsored Brands. /n The Benchmarker will create a personalized report that accurately benchmarks your ad performance and KPIs against your true competitors’. /n In addition to the account overview, you’ll find a deep-dive analysis of your top 100 ASINs ad performance – helping you to spot product-specific growth opportunities at a glance."
        )
      },
      {
        title: t("Is the Benchmarker really available for free?"),
        content: t(
          "Yep! Access to the report is completely free—all you have to do is sign up today."
        )
      },
      {
        title: t(
          "How can I get access to the customized Amazon PPC Benchmarker?"
        ),
        content: t("Sign up today and follow the prompts.")
      },
      {
        title: t(
          "I’m already a Perpetua customer – can I still get the Benchmarker?"
        ),
        content: t(
          "Absolutely, we encourage it! The Benchmarker can highlight product-specific growth potential and show you how to make the most of using Perpetua. /n To get started, request a report today. /n A head’s up that because the Benchmarker and Perpetua run on different data infrastructures, we can’t run the report directly from your Perpetua account—and will need your authorization to integrate: simply follow the prompts to get your report. /n If you have multiple accounts, the Benchmarker will attempt to integrate to the one which you’re currently logged into. To choose a different account, log out and log back in before making your request."
        )
      },
      {
        title: t("How do I know if I’m eligible for the Benchmarker?"),
        content: t(
          "If you’re actively using Amazon Advertising, you’re eligible! By actively, we mean at least 50 clicks on your last month’s ads needed for a meaningful analysis."
        )
      },
      {
        title: t("How accurate are the benchmarks that power the Benchmarker?"),
        content: t(
          "The short answer: very. /n The long answer: The Benchmarker data is based on aggregated and anonymized Amazon PPC data across 170 million products and 20,000 product categories. /n All of the benchmarks that you see in the Benchmarker are screened for statistical significance and biases to ensure accuracy. n Finally, to avoid muddying the waters with unreliable averages – which can be skewed by outliers – we use medians by percentile instead. /n We’re proud that the Benchmarker has the most accurate benchmarks in the industry. "
        )
      },
      {
        title: t("Where do the benchmarks in the Benchmarker come from?"),
        content: t(
          "The benchmarks are based on an internal study using aggregated and anonymized Amazon PPC data across 170,000 products and 20,000 product categories. /n We’re proud that the Benchmarker has the most accurate benchmarks in the industry. "
        )
      },
      {
        title: t("How many Benchmarkers can I get?"),
        content: t(
          "There’s no limit to the number of reports or accounts and we’ll automatically deliver an updated report to your inbox each month. /n Please note that you will always get a report for the last calendar month. Requesting a new report before the current month has passed will not create an updated report. "
        )
      },
      {
        title: t(
          "What is considered a ‘good’ ACOS/CPC/Click-through rate/Conversion rate for Amazon PPC?"
        ),
        content: t(
          "The Benchmarker answers this question for you. /n It depends on your: /n • Amazon marketplace /n • product category /n • ad format. /n For example, did you know that Sponsored Products ads in the Health & Household category have a median conversion rate 3x higher than Clothing? /n The Benchmarker gets even more granular than that – showing you benchmarks by sub-category and an ASIN-performance deep dive. Sign up for the Benchmarker today to (finally) get useful benchmarks relevant to your Amazon business."
        )
      },
      {
        title: t(
          "How can I use the Benchmarker to improve my performance on Amazon?"
        ),
        content: t(
          "The Benchmarker does two things: /n 1. It gives you an accurate picture of your Amazon PPC performance – across Sponsored Products and Sponsored Brands. /n 2. It highlights specific actionable insights – showing you changes you can make immediately to increase the profitability of your PPC campaigns."
        )
      },
      {
        title: t("What improvements have been made to the Benchmarker?"),
        content: t(
          "While we’re constantly making improvements to the Benchmarker, we’re especially proud of our Product Analysis launch, when we added 20,000 sub-category benchmarks and a top 100 ASIN deep dive – a performance table that shows you where your greatest strengths and weaknesses are specific to each product. /n Most recently, we launched some truly game-changing features we think you’ll really like: /n 1. A performance score that assesses your overall Amazon PPC performance and potential. /n 2. An illustrated performance funnel infographic that shows all of your Amazon PPC KPIs in one place. /n 3. A month-over-month progress report – beginning with your second monthly Benchmarker report. /n 4. An account structure analysis showing how your structure compares to the competition and giving recommendations for improvements. /n Not just insights, but actionable ones: inside your report, we’ll give concrete examples on how to optimize immediately, like how to improve your ACOS, CPC, click-through rate, and conversion rate. /n We have many more improvements which are already underway, so stay tuned! "
        )
      },
      {
        title: t(
          "Can I use the Benchmarker to measure my progress with Amazon PPC over time?"
        ),
        content: t(
          "Yes! To help you do so, we’ll send you a fresh Benchmarker report each month so that you can review how changes have impacted your performance. /n Of course, you’re free to opt out of the reports at any time."
        )
      },
      {
        title: t("What are people saying about the Benchmarker?"),
        content: t(
          "We’re humbled by all of the positive feedback we’ve received about the Benchmarker. /n Our users have called the Benchmarker “Very detailed and helpful”, “Very insightful”, “Amazing!!”, and said it’s “great to know where I stand”, and “I like seeing all the metrics. Especially laid out as clearly as they are. It is easily digestible information like this.” /n Self-made entrepreneur, founder, investor, and educator Tatiana James said: “If you want to make the most of your ad spend, then I would encourage you to check out […] the Benchmarker”. /n Sign up today to see for yourself."
        )
      },
      {
        title: t("What time period does the Benchmarker analyze?"),
        content: t(
          "Every new report will incorporate data from the previous calendar month. There is a minimum of 50 clicks on your last month’s ads needed for a meaningful analysis."
        )
      }
    ]
  };

  const demoReportCta = {
    text: t("See Demo Report"),
    route: t("https://benchmarker.perpetua.io/report/demo-us")
  };

  return (
    <Layout
      theme="indigo"
      primaryButtonId={CTA_ID.getStartedNav.id}
      secondaryButtonId={CTA_ID.signinNav.id}
      locale={locale}
    >
      <SEO
        title={t("Perpetua Benchmarker – Market-Leading Amazon PPC Evaluation")}
        description={t(
          "Get a monthly, FREE report detailing the performance of your PPC campaigns on Amazon. Try it now!"
        )}
        image={
          language != "de"
            ? `https://perpetua.io${pageData?.openGraphImageEN?.publicURL}`
            : `https://perpetua.io${pageData?.openGraphImageDE?.publicURL}`
        }
      />
      <div className="bg-gradient-to-b from-indigo-3-dark to-purple-3-dark overflow-hidden">
        <HeroSection
          gradientTitle={hero?.gradientTitle}
          gradientPosition={1}
          cta={hero?.cta}
          marqueeData={hero?.marqueeData}
          addSales={hero?.addSales}
        />
        <DemoReport cta={demoReportCta} />
      </div>

      <div className="bg-indigo-3-dark">
        <YourBusinness
          gradientTitle={[
            t("How can the Benchmarker"),
            t("help your business?")
          ]}
          gradientPosition={2}
          testimonialsData={testimonials}
        />
        <PowerfulTool
          gradientTitle={[
            t("Simply the"),
            t("most powerful Amazon PPC evaluation tool in the industry ")
          ]}
          gradientPosition={2}
          features={toolFeatures}
        />
      </div>
      <SafeAndSecure
        gradientTitle={["100%", t("safe & secure")]}
        gradientPosition={2}
        subtitle={
          <Trans i18nKey="privacy-and-terms">
            {" "}
            See our{" "}
            <a href={t("https://perpetua.io/privacy/")}>
              Privacy Policy
            </a> and <a href="https://perpetua.io/tos/"> Terms & Conditions</a>
          </Trans>
        }
        data={safeAndSecureData}
      />
      <AnalyzedNumbers
        data={analysedNumbersData.data}
        faqs={analysedNumbersData.faqs}
      />
      <Performance
        gradientTitle={[
          t("Evaluating your Amazon PPC performance is as"),
          t("easy as 1, 2, 3")
        ]}
        gradientPosition={2}
        data={performanceData}
        receiveBadgeGradient={[
          t("Receive a badge"),
          t("based on your performance")
        ]}
      />
      <Badges />
      <BottomCTA
        className="mt-30"
        primaryButtonId={""}
        title={t("Amazon PPC analysis is just a click away")}
        primaryButtonText={t("Analyze now")}
        primaryButtonClassName={""}
        primaryButtonOnClick={() => {
          trackView(Category.Benchmarker + " LP", {
            category: Category.Benchmarker,
            label: Name.Cta + " (Footer)"
          });
          helpers
            .delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: "Analyze now",
              page_url: window.location.href,
              destination: "https://benchmarker.perpetua.io/",
              location: Location.BottomCta
            })
            .then(() =>
              nav(appendTrackingParams(t("https://benchmarker.perpetua.io/")))
            );
        }}
      />
    </Layout>
  );
};

export default Benchmarker;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    danRogers: file(relativePath: { eq: "benchmarker/dan-rogers.png" }) {
      childImageSharp {
        gatsbyImageData(width: 294, height: 400)
      }
    }
    benDonovan: file(relativePath: { eq: "benchmarker/ben-donovan.png" }) {
      childImageSharp {
        gatsbyImageData(width: 294, height: 400)
      }
    }
    vovaEven: file(relativePath: { eq: "benchmarker/vova-even.png" }) {
      childImageSharp {
        gatsbyImageData(width: 294, height: 400)
      }
    }
    summary: file(relativePath: { eq: "benchmarker/summary.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    matrix: file(relativePath: { eq: "benchmarker/performance-matrix.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    impact: file(relativePath: { eq: "benchmarker/impact-drivers.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    analysis: file(relativePath: { eq: "benchmarker/prod-analysis.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    structure: file(relativePath: { eq: "benchmarker/account-structure.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    ad: file(relativePath: { eq: "benchmarker/ad-formats.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    openGraphImageEN: file(
      relativePath: { eq: "benchmarker/openGraphEN.png" }
    ) {
      publicURL
    }
    openGraphImageDE: file(
      relativePath: { eq: "benchmarker/openGraphDE.png" }
    ) {
      publicURL
    }
  }
`;
