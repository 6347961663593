import React, { createRef, useEffect, useState } from "react";
import Section from "../../../containers/Section";
import Badge from "../../Badge";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import Icon, { IconType } from "../../../components/Icon";
import { nav } from "../../../utils/navigation";
import GradientTitle from "../../GradientTitle";
import BackgroundCircles from "../../../images/benchmarker/background-circles.svg";
import BenchmarkerLogo from "../../../images/benchmarker/benchmarker-logo.svg";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Marquee from "../../Marquee";
import { useI18next } from "gatsby-plugin-react-i18next";
import { trackView, appendTrackingParams } from "../../../utils/analytics";
import { Category, Name } from "../../../utils/analytics/constants";

interface HeroSectionProps {
  h1Title?: string;
  gradientTitle?: string[];
  gradientPosition?: number;
  image?: any;
  cta?: any;
  marqueeData?: any;
  addSales?: string
}

const HeroSection: React.FC<HeroSectionProps> = props => {
  const { gradientTitle, gradientPosition, h1Title, cta, image, marqueeData, addSales } = props;
  const { language } = useI18next();
  const data = useStaticQuery(
    graphql`
      query {
        backgroundCircles: file(
          relativePath: { eq: "benchmarker/background circles.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logoReel: file(relativePath: { eq: "benchmarker/logo-reel.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        logoReelMobile: file(relativePath: { eq: "benchmarker/logoReelMobile.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        demoReport: file(relativePath: { eq: "benchmarker/demo-report.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const [amount, setAmount] = useState("");
  useEffect(() => {
    const total = setInterval(
      () =>
        setAmount(() => {
          const newCount =
            2065226480.03 +
            Math.floor((Date.now() - 1622505600000) / 100) * 6.78;
          return newCount.toLocaleString("de-DE", {
            maximumFractionDigits: 0
          });
        }),
      1000
    );

    return () => {
      clearInterval(total);
    };
  }, []);

  return (
    <Section className="pt-35 mx-auto flex justify-center flex-col items-center relative z-20">
      <Badge
        logo={<BenchmarkerLogo />}
        page="benchmarker"
        className="mb-20"
        title={h1Title}
        iconType={IconType.prism}
      />
      <div className="w-full items-center mx-auto max-w-xl lg:max-w-4xl">
        <GradientTitle
          title={gradientTitle}
          gradientPosition={gradientPosition}
          color={"blue"}
          variant={"h1"}
          lineBreak={false}
          className="lg:text-6xl text-center text-white"
        />
      </div>
      <div className="relative items-center flex flex-col">
        <BackgroundCircles className="absolute opacity-50 z-10" />
        <div className="z-20 w-full">
          <Button
            onClick={() => {
              trackView(Category.Benchmarker + " LP", {
                category: Category.Benchmarker,
                label: Name.Cta + " (Header)"
              });
              nav(appendTrackingParams(cta.route));
            }}
            className="mx-auto"
            variant={ButtonVariant.primary}
            text={cta.text}
          />
          <p className="bg-gradient-to-r h-8 bg-clip-text my-8 from-indigo-1 to-blue-1 text-4xl font-bold text-transparent text-center">
            {language !== "de" ? "$" : "€"}{amount}
          </p>
          <p className="bg-white relative text-sm px-2 rounded-3xl z-20 left-0 right-0 mx-auto max-w-max text-center align-center">
            <span className="bg-gradient-to-r bg-clip-text my-8 from-indigo-1 to-blue-1 text-transparent">
              {addSales}
            </span>
          </p>
        </div>
      </div>
      <GatsbyImage
        image={data?.logoReel?.childImageSharp?.gatsbyImageData}
        alt="logo rel"
        className="w-full max-w-2xl mt-18 hidden sm:block"
      />
      <GatsbyImage
        image={data?.logoReelMobile?.childImageSharp?.gatsbyImageData}
        alt="logo rel"
        className="w-full max-w-2xl mt-18 block sm:hidden"
      />
      <Marquee content={marqueeData} />
    </Section>
  );
};

export default HeroSection;
