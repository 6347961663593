import React, { useState } from "react";
import Section from "../../../containers/Section";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { nav } from "../../../utils/navigation";
import FormModal from "../../FormModal";
import Modal from "../../Modal";

interface AnalyzedNumbersProps {
  faqs?: any;
  data?: any;
}

const AnalyzedNumbers: React.FC<AnalyzedNumbersProps> = props => {
  const { faqs, data } = props;
  const [activeModal, setActiveModal] = useState<boolean>(false);
  return (
    <Section className="py-10 lg:py-16 mx-auto flex flex-col justify-center items-center relative">
      <div
        className="grid grid-rows-2 sm:grid-rows-1 sm:grid-cols-3 gap-9 sm:gap-0 lg:gap-32 uppercase"
        data-sal="slide-up"
        data-sal-duration="500"
        data-sal-delay="300"
        data-sal-easing="ease"
      >
        {data &&
          data.map((item, index) => {
            return (
              <div key={index} className="text-center">
                <p
                  className={`text-6xl lg:text-7xl font-bold text-transparent relative bg-gradient-to-r bg-clip-text ${item?.className}`}
                >
                  {item?.number}
                </p>
                <p className="font-semibold text-xl">{item?.text}</p>
              </div>
            );
          })}
      </div>
      <Button
        id=""
        className="mt-16 md:text-3xl"
        variant={ButtonVariant.indigoDark2}
        size={ButtonSize.large}
        text={"FAQ"}
        onClick={() => {
          setActiveModal(true);
        }}
      />
      {activeModal && (
        <Modal
          page={"benchmarker"}
          setActiveModal={setActiveModal}
          title={"Frenquently asked questions"}
          isActive={activeModal}
          data={faqs}
        />
      )}
    </Section>
  );
};

export default AnalyzedNumbers;
