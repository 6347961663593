import React from "react";
import Section from "../../../containers/Section";
import GradientTitle from "../../GradientTitle";
import Testimonials from "./Testimonials";

interface YourBusinnessProps {
  gradientTitle?: string[];
  gradientPosition?: number;
  testimonialsData?: any;
  image?: any;
}

const YourBusinness: React.FC<YourBusinnessProps> = props => {
  const { gradientTitle, gradientPosition, testimonialsData } = props;

  return (
    <Section className="pt-14 lg:py-40 mx-auto flex justify-center flex-col items-center relative">
      <div className="relative flex flex-col lg:flex-row w-full">
        <GradientTitle
          title={gradientTitle}
          gradientPosition={gradientPosition}
          color={"blue"}
          variant={"h2"}
          lineBreak={true}
          className="lg:text-6xl text-white max-w-xl mx-auto lg:m-0"
        />
        <Testimonials testimonialsData={testimonialsData} />
      </div>
    </Section>
  );
};

export default YourBusinness;
