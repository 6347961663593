import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundCircles from "../../../../images/benchmarker/background-circles.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import BigArrow from "../../../../images/big-arrow.svg";
import cn from "classnames";
import useMedia from "use-media";

interface TestimonialsProps {
  testimonialsData?: any;
}

const Testimonials: React.FC<TestimonialsProps> = props => {
  const { testimonialsData } = props;

  const isDesktop = useMedia({ minWidth: "1024px" });

  const [selectedItem, setSelectedItem] = useState<number>(0);

  return (
    <div className="relative">
      <BackgroundCircles className="absolute opacity-100 lg:opacity-50 z-10 left-24 sm:left-64 lg:left-32" />
      <div className="text-white z-20 relative">
        <div className="flex flex-col xl:flex-row relative">
          <GatsbyImage
            alt={testimonialsData[selectedItem]?.name}
            image={testimonialsData[selectedItem]?.image}
            style={{ width: "294px", height: "400px" }}
            className="sm:ml-40 lg:ml-0"
          />
            <div
              className={cn("transition duration-100 ease-in z-0 mt-18 lg:max-w-md xl:max-w-xs 2xl:max-w-md md:ml-16 lg:ml-0 xl:ml-16")}
            >
              <p className="text-xl sm:text-3xl sm:font-semibold lg:min-h-[160px]">
                {testimonialsData[selectedItem]?.description}
              </p>
              <p className="mt-3 text-base">
                {testimonialsData[selectedItem]?.name}
              </p>
            </div>
        </div>

        <div className="bg-white p-3 px-5 rounded-full flex justify-between absolute -translate-y-[400%] sm:-translate-y-[380%] lg:-translate-y-[460%] xl:-translate-y-1/2 xl:translate-x-full">
          <BigArrow
            onClick={() =>
              selectedItem > 0 && setSelectedItem(selectedItem - 1)
            }
            className={cn(
              "rotate-180 cursor-pointer mr-3",
              selectedItem === 0 && "cursor-not-allowed opacity-20"
            )}
          />
          {testimonialsData.map((option, i) => {
            return (
              <button
                key={i}
                className={cn(
                  "rounded-full text-xl text-white border w-10 flex items-center justify-center text-center mx-3",
                  selectedItem === i
                    ? "bg-indigo-1 border-indigo-1"
                    : "border-indigo-1 bg-transparent text-indigo-1 opacity-50"
                )}
                onClick={() => setSelectedItem(i)}
              >
                {i + 1}
              </button>
            );
          })}
          <BigArrow
            onClick={() =>
              selectedItem < testimonialsData.length - 1 &&
              setSelectedItem(selectedItem + 1)
            }
            className={cn(
              "cursor-pointer ml-3",
              selectedItem === testimonialsData.length - 1 &&
                "cursor-not-allowed opacity-20"
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
