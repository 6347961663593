import React from "react";
import Close from "../../images/close.svg";
import Accordion from "../Accordion";

interface ModalProps {
  setActiveModal?: any;
  isActive?: boolean;
  title?: string;
  data?: any;
  page?: string;
  className?: string;
}

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = props => {
  const { setActiveModal, data, title, page } = props;

  return (
    <>
      <div
        onClick={() => setActiveModal(false)}
        className="fixed top-0 h-full w-full bg-purple-2-dark bg-opacity-80 z-50"
      ></div>
      <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center w-[90%] h-full mt-5 max-w-[750px] max-h-[740px]">
        <div className="sm:rounded-2xl overflow-hidden w-full bg-white px-2 py-16 sm:py-12 flex flex-col">
          <p className="text-3xl font-semibold mb-8 px-6">{title}</p>
          <div className="overflow-y-scroll px-6">
          {page === "benchmarker" &&
            data &&
            data?.map((item, index) => {
              return (
                <Accordion
                  key={index}
                  title={item.title}
                  content={item.content}
                  maxContentHeight={'max-h-[740px]'}
                  type="faq"
                />
              );
            })}
          </div>
        </div>
        <button
          onClick={() => setActiveModal(false)}
          className="absolute bg-indigo-1 h-10 w-10 -top-3 -right-3 rounded-full flex items-center justify-center focus:outline-none"
        >
          <Close />
        </button>
      </div>
    </>
  );
};

export default React.memo(Modal);
