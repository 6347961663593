import React, { createRef, useEffect } from "react";
import Section from "../../../containers/Section";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { nav } from "../../../utils/navigation";
import { runAnimation } from "../../../utils/animations";
import BadgeCertificates from "../../../animations/BenchmarkerPage/benchmarker-certificate-bounce.json";
import Grids from "../../../animations/BenchmarkerPage/space-grid-white.json";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

interface DemoReportProps {
  cta?: any;
}

const DemoReport: React.FC<DemoReportProps> = props => {
  const { cta } = props;
  const gridsAnimationContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const grids =
      BadgeCertificates && runAnimation(gridsAnimationContainer.current, Grids);

    return () => {
      grids.destroy();
    };
  }, []);

  const data = useStaticQuery(
    graphql`
      query {
        demoReport: file(relativePath: { eq: "benchmarker/demo-report.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );
  return (
    <div className="sm:my-18 lg:my-0 mx-auto items-center relative pb-18 lg:pb-12">
      <div className="relative flex items-center group justify-start z-20 overflow-hidden rounded-xl max-w-[300px] sm:max-w-lg lg:max-w-3xl mx-auto">
        <GatsbyImage
          image={data?.demoReport?.childImageSharp?.gatsbyImageData}
          alt="Perpetua Benchmarker"
          className="w-full mx-auto transition-all duration-500 ease-in-out rounded-xl transform bg-center bg-cover group-hover:scale-125"
        />
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <Button
            onClick={() => {
              nav(cta?.route);
            }}
            className="mx-auto md:text-4xl z-20 bg-indigo-3-dark hover:shadow-2xl "
            variant={ButtonVariant.indigoDark}
            text={cta?.text}
            size={ButtonSize.large}
          />
        </div>
        <div className="absolute rounded-xl z-10 p-4 bottom-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-indigo-1 opacity-0 group-hover:h-full group-hover:opacity-50 duration-500"></div>
      </div>
      <div
        ref={gridsAnimationContainer}
        className="mx-auto absolute top-16 xl:-top-52 2xl:-top-96 z-10 w-full"
      ></div>
    </div>
  );
};

export default DemoReport;
