import React from "react";
import Section from "../../../containers/Section";
import GradientTitle from "../../GradientTitle";

interface PerformanceProps {
  gradientTitle?: string[];
  gradientPosition?: number;
  subtitle?: string;
  data?: any;
  receiveBadgeGradient?: string[];
}

const Performance: React.FC<PerformanceProps> = props => {
  const { gradientTitle, gradientPosition, subtitle, data, receiveBadgeGradient } = props;

  return (
    <Section className="pt-14 md:pt-32 md:pb-12 mx-auto flex flex-col justify-center items-center relative">
      <GradientTitle
        title={gradientTitle}
        gradientPosition={gradientPosition}
        color={"blue"}
        variant={"h2"}
        lineBreak={true}
        className="lg:text-6xl max-w-xs sm:max-w-3xl text-center"
      />
      <div className="w-full mx-auto relative">
        {data &&
          data.map((item, i) => {
            return (
              <div className="relative max-w-3xl mx-auto">
                <div
                  className="my-28 text-center"
                  data-sal="slide-up"
                  data-sal-duration="300"
                  data-sal-delay={`${i + 2}00`}
                  data-sal-easing="ease"
                >
                  <span className="z-20 relative bg-gradient-to-b mx-4 from-indigo-1-dark to-purple-2-dark py-4 px-6 rounded-full text-white text-3xl font-bold">
                    {i + 1}
                  </span>
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-duration="300"
                  data-sal-delay={`${i + 2}00`}
                  data-sal-easing="ease"
                  className={`absolute p-3 sm:p-6 sm:text-3xl sm:font-semibold border border-gray-5-light bg-white rounded-lg shadow ${item.className}`}
                >
                  <p>{item.text}</p>
                </div>
                <div className="z-10 absolute right-0 left-0 rotate-90 mt-18 sm:mt-60 xl:mt-72">
                  <div
                    data-sal="slide-up"
                    data-sal-duration="300"
                    data-sal-delay={`${i + 3}00`}
                    data-sal-easing="ease"
                    className="flex items-center justify-center border-l border-dashed border-blue-2-light border-t h-0 w-22 "
                  ></div>
                </div>
              </div>
            );
          })}
        <div
          data-sal="slide-up"
          data-sal-duration="300"
          data-sal-delay={"400"}
          data-sal-easing="ease"
          className="flex justify-center"
        >
          <GradientTitle
            title={receiveBadgeGradient}
            gradientPosition={gradientPosition}
            color={"blue"}
            variant={"h2"}
            lineBreak={true}
            className="lg:text-4xl max-w-3xl font-semibold text-center"
          />
        </div>
      </div>
    </Section>
  );
};

export default Performance;
