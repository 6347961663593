import React from "react";
import Icon from "../Icon";

export interface MarqueeProps {
  content?: any;
}

const Marquee: React.FC<MarqueeProps> = props => {
  const { content } = props;
  return (
    <div className="relative flex overflow-x-hidden mt-6 lg:mt-0">
      <div className="py-12 animate-marquee whitespace-nowrap flex">
        {content?.map((item, index) => {
          return (
            <span
              key={index}
              className="text-5xl text-transparent mx-4 stroketext-white uppercase flex justify-center items-center tracking-[0.08em]"
            >
              {item.text}
              {item.icon && (
                <Icon
                  className="ml-8 mr-4 sm:w-8 sm:h-8 animate-scaleIn"
                  iconType={item.icon}
                />
              )}
            </span>
          );
        })}
      </div>

      <div className="absolute top-0 py-12 animate-marquee2 whitespace-nowrap flex">
        {content?.map((item, index) => {
          return (
            <span
              key={index}
              className="text-5xl text-transparent mx-4 stroketext-white uppercase flex justify-center items-center tracking-[0.08em]"
            >
              {item.text}
              {item.icon && (
                <Icon
                  className="ml-8 mr-4 sm:w-8 sm:h-8 animate-scaleIn"
                  iconType={item.icon}
                />
              )}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default Marquee;
