import React, { createRef, useEffect, useState } from "react";
import Section from "../../../containers/Section";
import { graphql, useStaticQuery } from "gatsby";
import GradientTitle from "../../GradientTitle";
import BackgroundCircles from "../../../images/benchmarker/background-circles.svg";
import Vector from "../../../images/benchmarker/vector.svg";
import Castle from "../../../animations/BenchmarkerPage/secure-castle.json";
import { runAnimation } from "../../../utils/animations";

interface SafeAndSecureProps {
  gradientTitle?: string[];
  gradientPosition?: number;
  subtitle?: any;
  data?: any;
}

const SafeAndSecure: React.FC<SafeAndSecureProps> = props => {
  const { gradientTitle, gradientPosition, subtitle, data } = props;

  const animationContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const anim = Castle && runAnimation(animationContainer.current, Castle);

    return () => {
      anim.destroy();
    };
  }, []);

  const queryData = useStaticQuery(
    graphql`
      query {
        backgroundCircles: file(
          relativePath: { eq: "benchmarker/background circles.png" }
        ) {
          childImageSharp {
            fluid(quality: 60, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <Section className="pt-14 md:py-32 mx-auto flex flex-col justify-center items-center relative">
      <GradientTitle
        title={gradientTitle}
        gradientPosition={gradientPosition}
        color={"blue"}
        variant={"h2"}
        className="lg:text-6xl"
      />
      <p className="font-normal text-base mb-8 max-auto text-center text-indigo-1">
        {subtitle}
      </p>
      <div className="w-full mx-auto relative">
        <Vector
          // style={{ transform: "translate(120%, 20%)" }}
          className="absolute w-1/5 translate-x-[125%] translate-y-[20%] hidden sm:block"
        />
        <Vector
          style={{ transform: "translate(-125%, 20%) scaleX(-1)" }}
          className="absolute w-1/5 rotate-90 right-0 hidden sm:block"
        />
        <div ref={animationContainer} className="mx-auto w-48 h-56"></div>
        <div className="flex flex-col sm:flex-row max-auto justify-around -mt-12 sm:-mt-32">
          {data &&
            data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col items-center text-center relative my-14 sm:mb-0 min-h-[272px] justify-start"
                >
                  <div className="relative">
                    <BackgroundCircles className="opacity-30 mx-auto absolute z-0 top-1/2 left-1/2 w-[120px] h-[120px] -translate-x-1/2 -translate-y-1/2 " />
                    <span className="rounded-full relative inline-block bg-white shadow-xl p-5 h-16 w-16">
                      {item?.icon}
                    </span>
                  </div>
                  <p className="text-3xl font-semibold mt-10 mb-6">{item?.title}</p>
                  <p className="max-w-xs lg:max-w-sm">{item?.description}</p>
                </div>
              );
            })}
        </div>
      </div>
    </Section>
  );
};

export default SafeAndSecure;
