import React, { useEffect, useState } from "react";
import Section from "../../../containers/Section";
import GradientTitle from "../../GradientTitle";
import { GatsbyImage } from "gatsby-plugin-image";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import classNames from "classnames";

interface PowerfulToolProps {
  gradientTitle?: string[];
  gradientPosition?: number;
  features?: any;
}

const PowerfulTool: React.FC<PowerfulToolProps> = props => {
  const { gradientTitle, gradientPosition, features } = props;

  const [selectedItem, setSelectedItem] = useState<number>(0);

  return (
    <Section className="pt-14 pb-10 md:py-36 mx-auto flex flex-col lg:flex-row justify-center items-center relative mt-60 sm:mt-0">
      <div className="lg:w-1/2 order-2 lg:order-1 lg:min-h-[546px]">
        <GatsbyImage
          alt={features[selectedItem]?.name}
          image={features[selectedItem]?.image}
        />
      </div>
      <div className="flex flex-col w-full lg:w-1/2 lg:pl-12 order-1 lg:order-2">
        <GradientTitle
          title={gradientTitle}
          gradientPosition={gradientPosition}
          color={"blue"}
          variant={"h2"}
          lineBreak={false}
          className="lg:text-5xl text-center sm:text-left text-white lg:max-w-lg"
        />
        <div className="flex flex-row flex-wrap pr-12 sm:pr-0 justify-center sm:justify-start text-white w-96 sm:w-full">
          {features &&
            features.map((feature, index) => {
              return (
                <button
                  className={classNames(
                    " sm:w-auto mr-4 my-4 relative flex flex-row items-center justify-center rounded-lg whitespace-nowrap transition-colors px-4 sm:px-6 py-3 text-base sm:text-lg font-semibold bg-indigo-3-light text-indigo-1 border-indigo-1 hover:text-indigo-3-dark border-2 hover:border-indigo-2-light active:border-purple-active-button",
                    selectedItem === index && "bg-indigo-2-light text-indigo-3-dark border-indigo-2-light"
                  )}
                  onClick={() => setSelectedItem(index)}
                >
                  {feature?.name}
                </button>
              );
            })}
        </div>
        <p className="text-white text-xl text-center sm:text-left sm:text-3xl max-w-lg lg:max-w-full font-semibold my-8 lg:min-h-[96px]">
          {features[selectedItem]?.description}
        </p>
      </div>
    </Section>
  );
};

export default PowerfulTool;
