import React, { createRef, useEffect } from "react";
import { runAnimation } from "../../../utils/animations";
import BadgeCertificates from "../../../animations/BenchmarkerPage/benchmarker-certificate-bounce.json";
import Grids from "../../../animations/BenchmarkerPage/space-grid-blue.json";

const Badges: React.FC<{}> = props => {
  const badgesAnimationContainer = createRef<HTMLDivElement>();
  const gridsAnimationContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const badges =
      BadgeCertificates &&
      runAnimation(badgesAnimationContainer.current, BadgeCertificates);
    const grids =
      BadgeCertificates && runAnimation(gridsAnimationContainer.current, Grids);

    return () => {
      badges.destroy();
      grids.destroy();
    };
  }, []);
  return (
    <div className="md:pb-32 mx-auto justify-center items-center relative overflow-hidden">
      <div
        ref={gridsAnimationContainer}
        className="mx-auto absolute translate-y-2/3 sm:translate-y-0 xl:-translate-y-2/4 w-full"
      ></div>
      <div
        ref={badgesAnimationContainer}
        className="w-[300px] top-0 left-0 right-0 mx-auto"
      ></div>
    </div>
  );
};

export default Badges;
